import { Link } from "@StarberryUtils";
import * as React from "react"
import { Button, Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import dateFormat from 'dateformat';

// images
import ImgPerson from "../../../images/person.jpg"
import blogImg1 from "../../../images/home/blog-img-01.jpg"
import blogImg2 from "../../../images/home/blog-img-02.jpg"
import blogImg3 from "../../../images/home/blog-img-03.jpg"
import ContactCard from "../../ContactCard/ContactCard"
// Style
import './LatestBlogs.scss';
import ScrollAnimation from 'react-animate-on-scroll';

import { LatestBlogs } from "../../../queries/common_use_query"


const LatestBlogsComponent = (props) => {
  //console.log("blogsss",props)

  const { loading, error, data } = LatestBlogs();

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    mobileFirst:true,
    centerMode: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint:1199,
         settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
               
            }
    },
      {
          breakpoint: 767,
           settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                 
              }
      },
      {
        breakpoint:99999,
         settings: {
                slidesToShow: 2,
                infinite: false,
                centerMode: false,
                variableWidth: true,
            }
    },
  ]
}

 const result = data?.insights.filter(item => item.Tag === props.Filter_by_Tag);
 
 const peopleInsightList = data?.insights.filter(item => item.Author === "Ramy Wali").slice(0,10)
 var peopleInsightTitle = "Recent Articles Written by Ramy Wali"
 var peopleInsightContent = "Discover the most recent articles authored by Ramy Wali, covering a wide range of subjects with thoroughness and lucidity. Whether it's emerging tech or cultural matters, Ramy's pieces provide new angles and insightful examinations. Keep abreast of the conversation with Ramy Wali's latest offerings."
 let datatoshow = props.isCommunity ? result : props.isPeoplePage ? peopleInsightList : data?.insights?.slice(0,8)
  return (
    <div className="latest-blogs text-center text-xl-left">
      <Container>
        <Row>
        {props.Filter_by_Tag === "The Pearl" || props.Filter_by_Tag === "Lusail City" || props.Filter_by_Tag === "Gewan Island" ?
           <Col xl={4}>
             {/*  <div className="animated"> */}
                 
             { props.Filter_by_Tag === "The Pearl" ?
               <strong className="sub-title">The Pearl Community Blogs and Articles</strong> : props.Filter_by_Tag === "Lusail City" ?
               <strong className="sub-title">Lusail Community Blogs and Articles</strong> : props.Filter_by_Tag === "Gewan Island" ?
               <strong className="sub-title">Gewan Island Community Blogs and Articles</strong> : ""}
               <h2 className="main-title">The insider’s view of Qatari property</h2>
               <p className="main-desc d-none d-md-block">With deep experience in the Qatar real estate market, we thrive on our insider knowledge. The Pearl Gates shares valuable insights into the local property scene. </p>
               <Link to={'/about/case-studies-and-insights'} className="btn btn-primary d-none d-xl-inline-block"><span>View More</span></Link>
             {/*  </div> */}
           </Col> :
       
          <Col xl={4}>
            {/*  <div className="animated"> */}
              <strong className="sub-title">{props.Insights_Block?.Small_Text}</strong>
              {props.isPeoplePage ? <h2 className="main-title">{peopleInsightTitle}</h2> :
              <h2 className="main-title">{props.Insights_Block?.Title}</h2>}
              {props.isPeoplePage ? <p className="main-desc  d-md-block">{peopleInsightContent}</p> :
              <p className="main-desc  d-md-block">{props.Insights_Block?.Content}</p>}
              {props.isPeoplePage ? <Link to={"/about/case-studies-and-insights/"} className="btn btn-primary d-none d-xl-inline-block"><span>View More</span></Link> :
              <Link to={props.Insights_Block?.CTA_1_Link} className="btn btn-primary d-none d-xl-inline-block"><span>{props.Insights_Block?.CTA_1_Label}</span></Link>  }          
            {/*  </div> */}
          </Col>}
          <Col xl={8}>
            <Slider {...settings} >
              {
                datatoshow?.map((item, index) => {
                  var date_of_item = item.Date && dateFormat(item.Date, "dd mmmm yyyy");
                  var split_tags = item.Tag ? item.Tag.split(', ') : '';

                  return (
                    //  <div className="animated">
                      <ContactCard
                        cardImg={item.Tile_Image}
                        cardTitle={item.Title}
                        date={date_of_item}
                        cardTag={item.Tag ? split_tags[0] : item.Category.replace('_', ' ')}
                        link_url={item.URL}
                        imagename={"insights.Tile_Image.tile_image"}
                        article_id={item.id} 
                        imagetransforms={item.imagetransforms}
                        externalLink={item.External_Link}
                        section="latest_blogs"
                      />
                    //  </div>
                  )
                })
              }
            </Slider>
            <Link to={'/about/case-studies-and-insights'} className="btn btn-primary d-xl-none"><span>View More</span></Link>
          </Col>
          {/*  </div> */}
        </Row>
      </Container>


    </div>
  )
}



export default LatestBlogsComponent