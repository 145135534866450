import React, {useRef, useEffect} from "react"
import Modal from "react-bootstrap/Modal"
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from 'gatsby'
import SEO from "../components/seo";
import Breadcrumbs_Insights from "../components/Breadcrumbs/Breadcrumbs_Insights"

import MemberBlock from "../components/MemberBlock/MemberBlock"
import CustomerReviews from "../components/CustomerReviews/CustomerReviews"
import PropertyPortfolio from "../components/PropertyPortfolio/PropertyPortfolio"
import LatestBlogsComponent from "../components/Home/LatestBlogs/LatestBlogs";

import {PeopleDetails} from "../queries/common_use_query"
import MemberForm from "../components/forms/member-form"
import LoaderLogo from '../images/loader_logo_new.svg';

import "../scss/custom.scss"
import VideoSlider from "../components/VideoSlider/VideoSlider";
import TeamDetailReview from "../components/TeamDetailReview/TeamDetailReview";

function PeoplesTemplate (props){
    const myRef = useRef(null)
    let path_name = (props.location.pathname).split('/');
    let get_url = path_name[path_name.length-1] !== "" ? path_name[path_name.length-1] : path_name[path_name.length-2];

    const {loading, error, data} = PeopleDetails(get_url);
    
    //console.log("get_url",get_url);
    //console.log("insightdata",data?.peoples);

    const scrollTopropertyDetails = () => window.scroll ({
        top: myRef.current.offsetTop - 100,
        left: 0,
        behavior: 'smooth'
    });

    var meta_title = '', meta_desc = '';

    if(data && data.peoples.length > 0){
      meta_title = data.peoples[0].Name+", "+data.peoples[0].Designation+" | Propert Experts";
      meta_desc = "Get to know the "+data.peoples[0].Name+", "+data.peoples[0].Designation+" at The Pearl Gates, Qatar, committed to helping you select your dream home or investment. Your real estate experts.";
    }
    

    return (
    <>
      <Layout Layout={'Without_Banner'} classNames="Without_Banner">

          {/* ======= Breadcrumbs ====== */}
          {/* <Breadcrumbs_Insights page_name="People" Heading={data && data.peoples[0].Name} location={props.location}/> */}
          {
            (loading) ? (
              // <div className="main-banner no_lines">
              //     <div className="loader_div">
              //         {/* <img src={LoaderLogo} alt="Loader" title="Loader" /><br />Loading... */}
              //     </div>
              // </div>
              <section className={"loader-wrapper"}>
                <div id="loader-wrapper">
                    <div id="loader" class="new-loader">
                        <div className="new-loader-anime"></div>
                        <img className="logo-white loader-logo" src={LoaderLogo}  alt="logo"/>
                    </div>
                    <div class="loader-section section-left"></div>
                    <div class="loader-section section-right"></div>
                </div>
            </section>
            ) : data && data.peoples.length > 0 && <>
                <SEO title={meta_title} description={meta_desc} />

                <Helmet/>

                <div className="page-content people_details">
                  <div className="container ">
                    <div className="row">
                      <div className="video-detail-template peolpe_details">
                        <Breadcrumbs_Insights page_name="People" Heading={data.peoples[0].Name} location={props.location} />
                      </div>
                    </div>
                  </div>

                  <MemberBlock data={data.peoples[0]} handleClick={scrollTopropertyDetails} />

                  {/* ======= Customer Review ====== */}
                  {/* <div ref={myRef}></div>
                  {data.peoples[0].Customer_Reviews.length > 0 && 
                  <CustomerReviews data={data.peoples[0].Customer_Reviews} /> } */}

                  {/* ======= My Portfolio ====== */}
                  {data.peoples[0].Category !== "Support" && (
                    <PropertyPortfolio people_name={data.peoples[0].Name} />
                  )
                  }
                  {
                    data.peoples[0].My_Property_Videos !== null &&
                    <VideoSlider data={data.peoples[0].My_Property_Videos} />
                  }
                  <TeamDetailReview people_name={data.peoples[0].Name} />
                  { data?.peoples[0]?.Team_Insights?.Show_Insights === true &&
                  <LatestBlogsComponent  isPeoplePage={true}/>}
                </div>
                
            </>
          }
      </Layout>
    </>
  )
}

export default PeoplesTemplate